import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
// import Insight from "../components/blog/insight";
// import HowTo from "../components/blog/howto";
import BlogCard from "../components/blog/blogcard";


const DemosPage = ({data, path}) => (
  <Layout
    title="Product Demos" subtitle={'60-second demos of GummySearch features'}
    includeBanner={true} lightHeader={true}
    seoComponent={<SEO path={path} title="Product Demos" description="60-second product demos of the most popular GummySearch features" />}
  >

    <div className="mt-12">
      <ul className={`mt-2 grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-8 md:grid-cols-3 sm:gap-x-6 lg:grid-cols-3 xl:gap-x-8`}>
        {data.posts.edges.map((edge) => (
          <BlogCard key={edge.node.frontmatter.slug} node={edge.node} />
        ))}
      </ul>
    </div>
  </Layout>
)

export const query = graphql`
query {
  posts: allMdx(
    filter: { frontmatter: { type : {eq: "demo"}, published: {ne: ""}}},
    sort: { order: ASC, fields: [frontmatter___order] }
  ) {
    edges {
      node {
        frontmatter {
          slug
          published
          author
          title
          type
          description
          imageUrl
          authorImage
        }
      }
    }
  }
}
`

export default DemosPage;
